export interface DownloadPdfPreferences {
  includeCoverPage: boolean;
  includeTenantLogo: boolean;
  includeClientLogo: boolean;
  includeMetaData: boolean;
  includeAttachments: boolean;
  includeReferences: boolean;
  includeDistributionLogs: boolean;
}

export const DefaultDownloadPdfPreferences: DownloadPdfPreferences = {
  includeCoverPage: false,
  includeTenantLogo: false,
  includeClientLogo: false,
  includeMetaData: false,
  includeAttachments: false,
  includeReferences: false,
  includeDistributionLogs: false,
};
